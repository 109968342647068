document.querySelector("#SelectAll").addEventListener("click",
    function() {
        document.querySelectorAll("input[type='checkbox']:not(#Phone)").forEach(checkbox => checkbox.checked = true);
    });

document.querySelector("#Phone").addEventListener("click",
    function (event) {
        if (event.target.checked) {
            document.getElementById("PhoneNumber").hidden = false;
        } else {
            document.getElementById("PhoneNumber").hidden = true;
        }
    });